<div class="mainform">
  <mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>Gestion des opérations</mat-expansion-panel-header>
    <table>
      <tr>
        <td>
          Créer une nouvelle opération<br>
          <a href="./assets/new_operation.yaml" download>Télécharger le modèle</a>
          <br>
          <app-input [value]="ope_model" color="white"
                     style="margin: 5px;max-width: 200px;" label="Créer depuis un modèle"
                     [options]="operation.opes" value_type="list"
                     (valueChange)="update_ope($event)"></app-input>


        </td>
        <td>
          Importer une opération<br>depuis votre poste<br>
          <app-upload-file (uploaded)="upload_operation($event)"
                           title="Ajouter une opération"
                           style="margin: 10px" format="text"
                           extensions=".yaml">
            Importer
          </app-upload-file>
          <br>
        </td>

        <td>

          /

          <button type="button" mat-raised-button (click)="update_url_ope()">
            Lien Web
          </button>



        </td>

        <td>
<!--          <button type="button" mat-raised-button (click)="delete_ope()">-->
<!--            Supprimer-->
<!--          </button>-->
          <button type="button" mat-raised-button (click)="download_ope()">
            Télécharger
          </button>
          <button type="button" mat-raised-button (click)="edit_ope()">
            Editer
          </button>

        </td>
      </tr></table>

  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>Edition</mat-expansion-panel-header>
  </mat-expansion-panel>



  <div *ngIf="operation.sel_ope">
    <div class="mat-headline-4" style="margin: 0px;">
      {{operation.sel_ope.title}}
      <mat-icon (click)="refresh()">refresh</mat-icon>
    </div>
    <small class="color-primary mat-headline-6">{{operation.sel_ope.description}}&nbsp;(v{{operation.sel_ope.version}})</small>

    <table style="line-height: 125%;">
      <tr style="font-size: medium">
        <td>
          Id: {{operation.sel_ope.id}}
        </td>
        <td style="text-align: right;">
          Blockchain: {{operation.sel_ope.network}}<br>
          <span style="color:red;font-size: x-large;" *ngIf="operation.sel_ope.warning.length>0">
            Warning: {{operation.sel_ope.warning}}
          </span>

        </td>
      </tr>
      <tr>
        <td>Version: {{operation.sel_ope.version}}</td>
      </tr>
    </table>


    <div class="color-warm mat-headline" *ngIf="sources?.length==0">Auncune source référencée dans le fichier d'opération</div>
    <mat-expansion-panel *ngIf="sources?.length>0">
      <mat-expansion-panel-header  class="color-primary">Sources des NFTs</mat-expansion-panel-header>
      <table style="text-align: left;">
        <tr>
          <th>Sources</th>
          <th>Visuels</th>
          <th>Nombre de NFTs par collection</th>
        </tr>
        <tr>
          <td>
            Les NFTs disponibles pour cette opérations sont extraits <br><br>
            <ul>
              <li *ngFor="let src of sources">
                    <span class="color-accent">{{src.connexion}}</span>
                    <span *ngIf="src.filter.owner"> possédés par <{{src.filter.owner | alias}}</span>
                    <span *ngIf="src.filter.collections.length>0"> des collections {{src.filter.collections.join(' - ')}}</span>

                ({{src.ntokens}})
              </li>
            </ul>
          </td>
          <td>
            <app-showroom [delay]="1" size="100px"
                          [collection_network]="operation.sel_ope.network"
                          [nfts]="nfts">
            </app-showroom>
          </td>
          <td>
            <ul>
              <li *ngFor="let k of collection_keys" [cdkCopyToClipboard]="k" style="cursor: copy;">
                {{k}} : {{collections[k]}}
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="operation.sel_ope.event">
      <mat-expansion-panel-header>Evénement associé & NFT live</mat-expansion-panel-header>
      <table>
        <tr>
          <th>Evénement</th>
          <th>NFTLive</th>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td>Titre de l'événement:</td>
                <td>{{operation.sel_ope.event.title}}</td>
              </tr>
              <tr>
                <td>Lieu</td>
                <td>{{operation.sel_ope.event.place}}</td>
              </tr>
              <tr>
                <td>Dates</td>
                <td>
                  <div *ngFor="let d of operation.sel_ope.event!.dates">
                    {{d.start}} -> {{d.end}}
                  </div>
                </td>
              </tr>
            </table>

          </td>
          <td *ngIf="operation.sel_ope.nftlive && operation.sel_ope.nftlive!.collections">
            Collections: {{operation.sel_ope.nftlive!.collections.join(' - ')}}<br>
            Configuration: {{operation.sel_ope.nftlive!.nft_target.configuration}}<br>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="operation.sel_ope.mining"  [expanded]="false">
      <mat-expansion-panel-header  class="color-primary">Paramètres de minage et communication</mat-expansion-panel-header>
      <table>
        <tr>
          <th>Stockage off-chain</th>
          <th>Communication nouveau wallet</th>
          <th>Communication nouveau NFT</th>
          <th>Paramétres par réseau cible</th>
        </tr>
        <tr>
          <td>
            Content: {{operation.sel_ope.mining.content_storage}}<br>
            Metadata: {{operation.sel_ope.mining.metadata_storage}}<br>
          </td>
          <td>
            <strong>Mail envoyer pour l'ouverture d'un wallet:</strong><br>
            <div
                 style="padding: 10px;margin: 10px;display: inline-block;max-width: 500px;width: 80%;"
                 class="mat-elevation-z4"
                 [innerHTML]="mails.new_account"></div>

          </td>
          <td>
            <strong>Mail envoyer pour le simple transfert:</strong><br>
            <div
                 style="padding: 10px;margin: 10px;display: inline-block;max-width: 500px;width: 80%;" class="mat-elevation-z4"
                 [innerHTML]="mails.transfer"></div>
          </td>

          <td>
            <div *ngFor="let network of operation.sel_ope.mining.networks">
              <strong>{{network.network}}:</strong>
              <ul>
                <li>Miner: {{network.miner}}</li>
                <li>Collection: {{network.collection}}</li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>


    <!-- Section lié au dispenser / a la distribution -->
    <mat-expansion-panel *ngIf="operation.sel_ope.dispenser?.visible" [expanded]="false">
      <mat-expansion-panel-header>Distribution par {{user.addr | alias}}</mat-expansion-panel-header>
      <table>
        <tr>
          <th>Propriétés</th>
          <th>Messages</th>
          <th>NFTs distribuables</th>
        </tr>

        <tr>
          <td>
            Si l'utilisateur fournit un email:<br>
            <ul *ngIf="operation.sel_ope!.mining && operation.sel_ope!.mining!.networks!.length>0">
              <li>Mineur: {{operation.sel_ope!.mining!.networks[0].miner}}</li>
              <li>Network: {{operation.sel_ope!.mining!.networks[0].network}}</li>
              <li>Collection: {{operation.sel_ope!.mining!.networks[0].collection}}</li>
            </ul>
          </td>
          <td [innerHTML]="showMessages('dispenser')"></td>
          <td td *ngIf="operation.sel_ope.dispenser?.collections?.length==0">
            Tous les NFTs des sources
          </td>
          <td *ngIf="operation.sel_ope.dispenser!.collections && operation.sel_ope.dispenser!.collections!.length>0">
            <ul >
              <li *ngFor="let col of operation.sel_ope.dispenser!.collections!">
                {{col}}
              </li>
            </ul>
          </td>
          <td style="text-align: right;">
            <button type="button" mat-raised-button color="primary"
                    (click)="open_appli('dispenser',{selfWalletConnexion:false})">
              Ouvrir
            </button>

            <br>
            <button type="button" mat-raised-button color="primary"
                    (click)="share_link(
                        get_url_for_appli('dispenser',{selfWalletConnexion:false}),
                    'Distributeur pour '+operation.sel_ope.title,
                    'Ouvrir ce lien distribuer des NFTs')">
              Partager
            </button>


          </td>
        </tr>
      </table>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="operation.sel_ope && operation.sel_ope.airdrop && operation.sel_ope.airdrop.visible"  [expanded]="false">
      <mat-expansion-panel-header>Airdrop</mat-expansion-panel-header>
      <table>
        <tr>
          <th>Collections impliquées</th>
          <th>QRCode d'accès</th>
          <th>Messages</th>
          <th>Partager le lien</th>
        </tr>
        <tr>
          <td>
            <ul>
              <li *ngFor="let col of operation.sel_ope.airdrop.collections">
                {{col}}
              </li>
            </ul>
          </td>
          <td>
            <a [href]="candymachine_qrcode" target="print">
              <img [src]="candymachine_qrcode" style="width: 200px;">
            </a><br>
            <small>cliquer pour visualiser en grand format</small>
          </td>
          <td [innerHTML]="showMessages('airdrop')"></td>
          <td style="text-align: right;">
            <button type="button" mat-raised-button color="primary"
                    (click)="open_appli('cm',{airdrop:true})">
              Ouvrir
            </button>
            <br>
            <button type="button" mat-raised-button color="primary"
                    (click)="share_link(get_url_for_appli('cm',{airdrop:true}),'Gagner des NFT','Recevoir des NFT gratuitement')">
              Partager
            </button>
          </td>
        </tr>
      </table>

    </mat-expansion-panel>




    <mat-expansion-panel *ngIf="operation.sel_ope.candymachine.visible && nfts.length>0"  [expanded]="false">
      <mat-expansion-panel-header>Distributeur automatique</mat-expansion-panel-header>
      <table>
        <tr>
          <th>Collections impliquées</th>
          <th>QRCode d'accès</th>
          <th>Messages</th>
          <th>Partager le lien</th>
        </tr>
        <tr>
          <td>
            <ul>
              <li *ngFor="let col of operation.sel_ope.candymachine.collections">
                {{col}}
              </li>
            </ul>
          </td>
          <td>
            <a [href]="candymachine_qrcode" target="print">
              <img [src]="candymachine_qrcode" style="width: 200px;">
            </a><br>
            <small>cliquer pour visualiser en grand format</small>
          </td>
          <td [innerHTML]="showMessages('candymachine')"></td>
          <td style="text-align: right;">
            <br>Vérifier bien que {{user.addr | alias}} dispose des droits pour distribuer les NFTs<br><br>
            <button type="button" mat-raised-button color="primary"
                    (click)="open_appli('cm',{airdrop:false})">
              Ouvrir
            </button>
            <br>
            <button type="button" mat-raised-button color="primary"
                    (click)="share_link(get_url_for_appli('cm',{airdrop:false}),'Gagner des NFT','Recevoir des NFT gratuitement')">
              Partager
            </button>

            <!--            <share-buttons [theme]="'circles-dark'"-->
            <!--                           style="text-align: center;font-size: small;"-->
            <!--                           [include]="['facebook','twitter','linkedin','telegram','messenger','whatsapp','email','copy']"-->
            <!--                           [show]="8"-->
            <!--                           [url]="url"-->
            <!--                           description="Découvrez mon profil et mes réalisations"-->
            <!--                           tags="FEMIS"-->
            <!--                           [title]="title">-->
            <!--            </share-buttons>-->
          </td>
        </tr>
      </table>
    </mat-expansion-panel>



    <mat-expansion-panel *ngIf="operation.sel_ope.lottery?.visible">
      <mat-expansion-panel-header  class="color-primary">Loterie</mat-expansion-panel-header>
      <table>
        <tr>
          <th>Propriété</th>
          <th>Collections impliquées</th>
          <th>Code à intégrer</th>
        </tr>
        <tr style="width: 100%;">
          <td>
            Mineur: {{operation.sel_ope.lottery?.miner}}<br>
            Network: {{operation.sel_ope.network}}
          </td>
          <td>
            <ul>
              <li *ngFor="let col of operation.sel_ope.lottery!.collections">
                {{col}}
              </li>
            </ul>
          </td>
          <td style="text-align: left;" *ngIf="operation!.sel_ope!.lottery">
            Store : <a [href]="operation.sel_ope.lottery.application" target="same">Ouvrir</a><br>
            Iframe: <mat-icon [cdkCopyToClipboard]="operation.sel_ope.lottery!.iframe_code">build</mat-icon><br>
            QRCode: <mat-icon [cdkCopyToClipboard]="operation.sel_ope.lottery!.image_code">build</mat-icon>
          </td>
          <td>
            <span *ngFor="let col of collections_lottery">

            </span>
          </td>
          <td style="text-align: right;">
            <button type="button" mat-raised-button color="primary"
                    (click)="open_appli('lottery')">
              Ouvrir
            </button>
            <br>

            <button type="button" mat-raised-button color="primary"
                    (click)="share_link(operation.sel_ope.lottery!.application,'Mode lotterie pour '+operation.sel_ope.title,'Ouvrir ce lien pour initier la distribution')">
              Partager
            </button>

          </td>
        </tr>
      </table>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="nfts.length>0 && (operation.sel_ope.validate?.visible || url_ope!.length>0)">
      <mat-expansion-panel-header>
        Validation
      </mat-expansion-panel-header>

      <table>
        <tr>
          <th>Paramètres de la validation</th>
          <th>Propriétés visibles du validateurs</th>
          <th>Liste des validateurs autorisés</th>
          <th>Collections à valider</th>

        </tr>
        <tr style="vertical-align: top;">
          <td>
            <div *ngIf="operation.sel_ope.validate!.method!">
              <strong>Update:</strong> {{operation.sel_ope.validate!.method!.update_authority!}}<br>
              <strong>Storage:</strong> {{operation.sel_ope.validate!.method!.storage}} / {{operation.sel_ope.validate!.method!.repository}}
            </div>

          </td>
          <td style="text-align: left;" *ngIf="operation.sel_ope.validate?.users">
            <ul>
              <li *ngFor="let prop of operation.sel_ope.validate!.properties">
                {{prop}}
              </li>
            </ul>
          </td>
          <td style="text-align: left;" *ngIf="operation.sel_ope.validate?.users">
            <ul>
              <li *ngFor="let user of operation.sel_ope.validate!.users">
                <mat-icon (click)="send_mail(user)"
                          style="cursor: pointer;font-size: medium;"
                          [title]="'notifier '+user">notifications</mat-icon>
                {{user}}
              </li>
            </ul>
            <button type="button" mat-raised-button (click)="send_mail()">
              Tous les notifier
            </button>
          </td>
          <td>
            <ul>
              <li *ngFor="let col of operation.sel_ope.validate!.filters!.collections">
                {{col}}
              </li>
            </ul>
          </td>

          <td style="text-align: right;">
            <button type="button" mat-raised-button color="primary"
                    title="Permet de tester le processus de validation"
                    (click)="open_appli('validate')">
              Ouvrir
            </button>


            <br>

            <button type="button" mat-raised-button color="primary"
                    (click)="share_link(operation.sel_ope.validate!.application,'validation '+operation.sel_ope.title,'Ouvrir ce lien pour commencer la validation')">
              Partager
            </button>

          </td>
        </tr>
      </table>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="operation.sel_ope!.store && (operation.sel_ope!.store?.visible || url_ope.length>0)">
      <mat-expansion-panel-header>
        Store
      </mat-expansion-panel-header>
      <table >
        <tr style="width: 100%;">
          <th>Collection en ventes</th>
          <th>Filtres</th>
          <th *ngIf="showPrestashop && operation.sel_ope!.store!.prestashop">Prestashop</th>
          <th>Vente directe</th>
        </tr>
        <tr>
          <td>
            <ul>
              <li *ngFor="let k of store_collections">
                {{k}}
              </li>
            </ul>
          </td>
          <td>
            <div *ngIf="operation.sel_ope!.store!.filter">
              De {{operation.sel_ope!.store!.filter!.from}} à {{operation.sel_ope!.store!.filter!.to}}
            </div>
          </td>
          <td *ngIf="showPrestashop && operation.sel_ope.store!.prestashop">
            Serveur: {{operation.sel_ope.store!.prestashop!.server}}<br>
            <a [href]="operation.sel_ope.store!.prestashop!.address.replace('$server$',operation.sel_ope.store!.prestashop!.server)" target="store">Voir la boutique</a>
            /
            <a [href]="operation.sel_ope.store!.prestashop!.admin.replace('$server$',operation.sel_ope.store!.prestashop!.server)" target="store">Accès admin</a>
            <br><br>
            <button type="button" mat-raised-button mat-button
                    (click)="send_prestashop(operation.sel_ope.id)">
              Transférer les NFTs
            </button>
            <button type="button" mat-raised-button mat-button
                    (click)="analyse_order(operation.sel_ope.id)">
              Analyser les commandes
            </button>
          </td>
          <td style="text-align: right;">
            <button type="button" mat-raised-button color="primary"
                    (click)="open_appli('store')">
              Ouvrir
            </button>
            <br>
            <button type="button" mat-raised-button mat-button
                    (click)="share_link(operation.sel_ope.store!.application+'?ope='+operation.sel_ope.id+'&toolbar=false','la boutique '+operation.sel_ope.title,'Ouvrir ce lien pour commencer la validation')">
              Partager
            </button>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>

  </div>

</div>
