<app-hourglass [message]="message" [modal]="true" fontsize="xx-large"></app-hourglass>

<div class="mainform" style="margin:0px;">
  <div class="titleform">{{title_form}}</div>
  <div class="subtitleform">{{claim}}</div>

  <div class="subtitleform" *ngIf="sel_config && sel_config.message && sel_config.message!.length>0">
    {{sel_config!.message}}
    <a *ngIf="sel_config && sel_config.help && sel_config.help.length>0" [href]="sel_config!.help"><mat-icon>help</mat-icon></a>
  </div>
  <mat-expansion-panel [expanded]="!sel_config">
    <mat-expansion-panel-header>Gestion des configurations</mat-expansion-panel-header>

    <div class="expansion-content">
      <app-upload-file (uploaded)="on_upload_config($event)"
                       [zone]="false"
                       format="text" [maxsize]="1000000"
                       title="Chargée une configuration depuis votre ordinateur"
                       extensions=".yaml">
        Importer
      </app-upload-file>


      <!--            <button type="button" mat-raised-button (click)="online_config()"-->
      <!--                    *ngIf="user.advance_mode"-->
      <!--                    title="Permet d'utiliser un fichier en ligne avec son url">-->
      <!--              {{"Fichier en ligne" | screencutter:"En ligne"}}-->
      <!--            </button>-->

      <button type="button" mat-raised-button (click)="reset()">
        Nouveau
      </button>

      <br>
      <div *ngIf="configs.length>0" style="display: flex;align-items: center;">
        <app-input value_type="list" [options]="configs"
                   [value]="sel_config"
                   label="Voir des exemples"
                   (valueChange)="update_config($event)"
                   width="30vw" maxwidth="300px">
        </app-input>
      </div>


      <!--            <button type="button" mat-raised-button *ngIf="sel_config" (click)="clone()">-->
      <!--              Cloner-->
      <!--            </button>-->



      <div style="width:30%;" *ngIf="sel_config">

        <button type="button" mat-raised-button (click)="save_config(true)"
        title="Enregristrer cette configuration sur votre terminal">
          Enregistrer cette configuration
        </button>

        <button type="button" mat-raised-button (click)="publish('nftstorage')"
                *ngIf="user.advance_mode"
                title="Permet de mettre en ligne cette configuration (pour usage dans les configurations)">
          Publier
        </button>
      </div>
    </div>



  </mat-expansion-panel>


  <!--  Travail sur les textes -->
  <div *ngIf="sel_config" >
    <mat-expansion-panel [expanded]="show_addtext!=null" *ngIf="user.advance_mode">
      <mat-expansion-panel-header>Ajout de texte</mat-expansion-panel-header>
      <table style="width: 100%;vertical-align: middle;">
        <tr>
          <td>
            <button style="width: fit-content" mat-raised-button mat-button
                    title="Ajouter une série d'identifiant"
                    (click)="add_serial(show_addtext)">
              Série numérique
            </button>
            <mat-checkbox [(ngModel)]="usePalette" *ngIf="sel_colors.length>0">Utiliser la palette sélectionnée</mat-checkbox>

            <br>

            <mat-form-field appearance="fill" style="max-width: 600px;width: 100%;" *ngIf="sel_config!.text">
              <mat-label>Texte a ajouter</mat-label>
              <input matInput type="text" [(ngModel)]="sel_config!.text!.text_to_add" title="Insérer __idx__ pour générer des séries automatiquement">
              <mat-icon matSuffix (click)="sel_config!.text!.text_to_add=''">cancel</mat-icon>
            </mat-form-field><br>

          </td>
          <td>
            <div style="width:200px;height: 200px;border: 1px solid black;cursor: crosshair;position: relative;" [style.background-color]="backcolor"
                 *ngIf="sel_config!.text"
                 (click)="onclick_on_text($event,show_addtext)">
              <div [ngStyle]="{
              'font-size':(200*sel_config!.text!.fontsize/100)+'px',
              position: 'absolute',display:'block',
              'z-index': 10,'pointer-events':'none',
              top:sel_config!.text!.position_text.y*2+'px',left:sel_config!.text!.position_text.x*2+'px',
              'width':'fit-content',color:sel_config!.text!.color}">
                {{sel_config!.text!.text_to_add.replace('__idx__','0').split("|")[0]}}
              </div>
            </div>
          </td>
          <td *ngIf="sel_config!.text">
            Couleur du texte : <input *ngIf="!usePalette" [(colorPicker)]="sel_config!.text!.color" [style.background]="sel_config!.text!.color"><br>
            X:<mat-slider [max]="100" [min]="0">
            <input matSliderThumb [(value)]="sel_config!.text!.position_text.x">
          </mat-slider>
            <input type="number" [(ngModel)]="sel_config!.text!.position_text.x" style="width:50px">
            <br>
            Y:<mat-slider [max]="100" [min]="0" >
            <input matSliderThumb [(value)]="sel_config!.text!.position_text.y">
          </mat-slider>

            <input type="number" [(ngModel)]="sel_config!.text!.position_text.y"  style="width:50px;">

            <br>

            <br>
            Fontes:
            <mat-select [(ngModel)]="sel_config!.text.font">
              <mat-option *ngFor="let f of fontfiles" [value]="f">{{f.name}}</mat-option>
            </mat-select>
            <br>
            FontSize:
            <mat-slider [max]="40" [min]="1" [step]="0.1">
              <input matSliderThumb [(value)]="sel_config!.text!.fontsize">
            </mat-slider>
            <input type="number" [(ngModel)]="sel_config!.text!.fontsize"  style="width:50px;">
            <br>

          </td>
          <td>

          </td>
          <td>

          </td>
        </tr>
      </table>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="show_conception" style="margin: 0;padding:0;">
      <mat-expansion-panel-header>Visuels / Images</mat-expansion-panel-header>
      <div class="expansion-content">
        <button type="button" mat-raised-button (click)="add_layer()" title="Ajouter un nouveau calque">
          <mat-icon>add_circle_outline</mat-icon> un calque
        </button>

        <button type="button" mat-raised-button mat-button
                color="primary" *ngIf="total_picture()>3"
                title="Voir un aperçu des 10 premiers NFT"
                (click)="preview(20,'75x75',100)">
          Aperçu rapide
        </button>

        <button type="button" mat-raised-button mat-button
                *ngIf="total_picture()>10 && user.advance_mode"
                title="Permet de voir tous les visuels d'un calque"
                (click)="show_max_item()">
          Voir toutes les images
        </button>
        <button type="button" mat-raised-button mat-button
                title="Afficher la transparence en noir (pour faire resortir les éléments blancs)"
                *ngIf="total_picture()>0 && user.advance_mode"
                (click)="backcolor=(backcolor=='white' ? 'black' : 'white')">
          Inverser le fond
        </button>
        <button style="width: fit-content" mat-raised-button mat-button
                title="Ouvrir des banques d'images"
                (click)="find_image()">
          Trouver des images<br>
          sur internet
        </button>

        <app-input label="Stockage visuels et metadata" *ngIf="user.advance_mode"
                   [value]="sel_platform" value_field="value"
                   [options]="stockage_available"
                   (valueChange)="sel_platform=$event"></app-input>

      </div>

      <br>

      <table style="padding: 0;width:100%;margin:0;">
        <tr>
          <th *ngIf="!(device.isHandset$ | async) && user.advance_mode">Nom</th>
          <th>Commandes</th>
          <th>
            <small>
              {{"Faites glisser des images depuis l'explorateur ou cliquer sur une image + CTRL pour la supprimer ou SHIFT pour la déplacer" | screencutter:"Calques"}}
            </small>
          </th>
        </tr>
        <tr *ngFor="let layer of sel_config!.layers"
            style="text-align: center;vertical-align: middle">

          <td style="width:50px;"
              (click)="edit_name(layer)" *ngIf="!(device.isHandset$ | async) && user.advance_mode"
              title="Cliquer pour rappeler les paramètres de fabrication">
            {{layer.name}}<br>
            <span style="font-size: x-small;">{{layer.elements?.length}} éléments</span>
          </td>

          <td style="width: 150px;text-align: left;line-height: 85%;" >

            <div style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
              <app-upload-file (uploaded)="on_upload($event,layer)" icon="image" [zone]="false"
                               [maxsize]="100000000"
                               title="Charger une image depuis votre ordinateur"
                               extensions=".png,.psd,.webp,.gif,.jpg,.svg,.yaml">
              </app-upload-file>
              <div *ngIf="user.advance_mode">
                <mat-icon style="cursor: pointer;" (click)="add_link(layer)" title="Inclure une image sous forme d'une adresse internet">link</mat-icon>
                <mat-icon style="cursor: pointer;" (click)="generate(layer)" title="Inclure du texte">notes</mat-icon>
                <mat-icon style="cursor: pointer;" (click)="apply_filter(layer)" title="Appliquer des filtres">filter</mat-icon>
                <mat-icon style="cursor: pointer;" (click)="paste_picture(layer)" title="Coller une image contenu dans le presse-papier">content_paste</mat-icon>
                <mat-icon style="cursor: pointer;" (click)="generate_with_color(layer)" title="Générer des copies par variation de couleurs">palette</mat-icon>
                <mat-icon style="cursor: pointer;" (click)="transform(layer)" [title]="'Bordure (px) '+layer.margin">edit</mat-icon>
              </div>
              <mat-icon style="cursor: pointer;" *ngIf="layer.position>0" (click)="update_position(layer,-1)" title="faire remonter le calque">arrow_circle_up</mat-icon>
              <mat-icon style="cursor: pointer;" *ngIf="layer.position<sel_config!.layers.length-1"  (click)="update_position(layer,+1)" title="faire descendre le calque">arrow_circle_down</mat-icon>
              <mat-icon style="cursor: pointer;" (click)="search_images(layer)" title="Chercher des images sur Internet pour intégrer directement dans le calque">image_search</mat-icon>
              <mat-icon style="cursor: pointer;" (click)="delete_layer(layer)" *ngIf="layer.elements!.length==0" title="Effacer la ligne">cancel</mat-icon>
              <mat-icon style="cursor: pointer;" (click)="clear_layer(layer)" *ngIf="layer.elements!.length>0" title="Effacer tous les éléments">delete</mat-icon>

            </div>

            <div style="line-height: 75%;">
              <mat-checkbox  [(ngModel)]="layer.indexed"
                             title="Signifie que les images de ce calque compte pour définir le caractère unique de chaque NFT"
                             (ngModelChange)="eval_max_nft()">Dans l'unicité</mat-checkbox><br>
              <mat-checkbox style="margin-top: -10px;" [(ngModel)]="layer.unique"
                            title="Signifie que chaque image ne peut être utilisé qu'une seul fois pour les NFTs à produire"
                            (ngModelChange)="eval_max_nft()">
                Usage unique
              </mat-checkbox>
            </div>


          </td>


          <td style="text-align: left;"
              appFileDragNDrop (filesChangeEmiter)="drop(layer,$event)"
              title="Faites glisser une image pour l'ajouter au calque">

            <div *ngFor="let elt of layer.elements?.slice(0,sel_config.max_items)"
                 [title]="elt.name+'\n'+elt.image.substring(0,100)+'\n\nCliquer pour voir à échelle 1:1'"
                 style="display: inline-block;margin: 5px;max-width: 100px;max-height: 100px;cursor: pointer;width:20%;height:100%;">
              <img *ngIf="elt?.image?.length>0"
                   [src]="elt.image"
                   alt="En cours de chargement"
                   style="width: 100%;"
                   [style.background-color]="backcolor" class="mat-elevation-z4"
                   (mousedown)="modify_element($event,layer,elt)"
                   (dblclick)="open_file(elt)">
            </div>
            <span *ngIf="layer.elements && sel_config.max_items<1000 && layer.elements.length>sel_config.max_items" style="font-size: xx-large;cursor: pointer;" (click)="sel_config.max_items=1000">...</span>
          </td>
        </tr>

      </table>
      <br>

    </mat-expansion-panel>

    <!--  preview-->

    <mat-expansion-panel [expanded]="show_preview">
      <mat-expansion-panel-header>Résultats / Prévisualisation</mat-expansion-panel-header>
      <app-hourglass [message]="message_preview"></app-hourglass><br>
      <button (click)="download_collection()" mat-raised-button
              *ngIf="url_collection.length>0"
              title="Télécharger l'ensemble des visuels dans un fichier zip"
              style="display:inline-block;height:60px;margin: 10px;">
        <mat-icon>download</mat-icon>
        <div style="display: inline-block;">Télécharger la collection</div>
      </button>
      <br>
      <br>

      <div style="font-size:x-small;width: 100px;margin:5px;display: inline-block;text-align: center;line-height: 85%;cursor: pointer;" *ngFor="let img of previews">
        <img [src]="img.src" [ngStyle]="img.style"
             [title]="img.url || img.index"
             (dblclick)="save_file(img)"
             (click)="select_file(img)">
      </div>

      <div *ngIf="previews.length>0">
        <br>
        double cliquer sur un visuel pour le télécharger
      </div>


    </mat-expansion-panel>




    <mat-expansion-panel [(expanded)]="show_generate">
      <mat-expansion-panel-header>Fabriquer la série de NFT</mat-expansion-panel-header>

      <app-input class="input-number" label="Nombre de NFT à générer"
                 *ngIf="max_nft>0"
                 [min]="1" [max]="max_nft" maxwidth="600px"
                 help="Cette quantité est limitée par le nombre de combinaison unique possible"
                 value_type="slider"
                 [value]="sel_config.limit" (valueChange)="sel_config.limit=$event"></app-input>

      <div style="display: flex;flex-wrap: wrap;">

        <mat-card appearance="outlined" style="width: 300px;">
          <mat-card-title>Propriétés des visuels</mat-card-title>
          <mat-card-content>

            <div *ngIf="user.advance_mode">
              <app-input label="Longeur" help="en pixels" [max]="2000" [min]="200"
                         value_type="number" [value]="sel_config.width" (valueChange)="sel_config.width=$event"></app-input>

              <app-input label="Hauteur" value_type="number" help="en pixels" [max]="2000" [min]="200"
                         [value]="sel_config.height" (valueChange)="sel_config.height=$event"></app-input>

              <app-input label="Qualité" [min]="0" [max]="100"
                         [value]="sel_config!.quality" maxwidth="400px"
                         (valueChange)="sel_config!.quality=$event"></app-input>

              <app-input label="Format des images"
                         value_type="list"
                         [options]="formats" [(value)]="sel_ext"></app-input>
            </div>

            <div *ngIf="!user.advance_mode" style="width: 100%;">
              <app-input label="Dimension du visuel (en pixels)" value_type="slider" [max]="2000" [min]="200" width="250px"
                         [value]="sel_config.height" (valueChange)="sel_config.height=$event;sel_config.width=$event"></app-input>

            </div>


          </mat-card-content>

        </mat-card>

        <mat-card *ngIf="user.advance_mode">
          <mat-card-title>Options avancées</mat-card-title>
          <mat-card-content class="expansion-content">

            <app-input class="input-number" label="Série aléatoire" value_type="number" [min]="0" [max]="100000"
                       title="La séquence aléatoire de NFT généré sera toujours la même pour un nombre donnée"
                       [value]="sel_config.seed" (valueChange)="sel_config.seed=$event">
            </app-input>

          </mat-card-content>

        </mat-card>
        <mat-card appearance="outlined" style="width: 250px;" *ngIf="message_preview==''">
          <mat-card-title>Fabrication</mat-card-title>
          <mat-card-content>

            <button (click)="generate_collection('zip')" mat-raised-button
                    title="Télécharger l'ensemble des visuels dans un fichier zip"
                    style="width: 100%">
              Générer
            </button>

            <button (click)="generate_collection('files zip');" mat-raised-button
                    *ngIf="user.profil?.email"
                    title="Télécharger l'ensemble des visuels dans un fichier zip"
                    style="width: 100%">
             Télécharger
            </button>



            <br>


<!--            <app-input *ngIf="user.advance_mode" label="Nom des fichiers téléchargés"-->
<!--                       [value]="filename_format" (valueChange)="filename_format=$event"></app-input>-->

            <button type="button" mat-raised-button color="primary" style="width: 100%;"
                    title="Déclenche la génération, la mise en ligne des visuels et bascule dans la fenêtre de minage"
                    *ngIf="network.networks_available.length>0"
                    (click)="ask_confirmation_for_big_collection('files mint')">
              Générer & Miner
            </button>

            <br>
<!--            <button type="button" mat-raised-button style="width: 100%;"-->
<!--                    title="Fabrique la collection et envoi un mail dans le fichier est disponible"-->
<!--                    (click)="ask_email_for_collection()">-->
<!--              Récupérer par mail<br>la collection-->
<!--            </button>-->

          </mat-card-content>
        </mat-card>
      </div>

      <br>

      <div *ngIf="upload_files.length>0">
        <div *ngFor="let url of upload_files">
          <a [href]="url">{{url}}</a>
        </div>
        <button type="button" mat-raised-button (click)="miner()">Miner</button>
      </div>
    </mat-expansion-panel>



    <mat-expansion-panel >
      <mat-expansion-panel-header>Attributs des NFTs</mat-expansion-panel-header>
      <small>Les données du NFT peuvent être inclusent dans les images pour utilisation au moment du minage</small>

      <br><br>
      <div class="expansion-content">
        <app-upload-file (uploaded)="on_upload_attributs($event)" height="35px"
                         title="Associer aux visuels un fichier de descriptions / attributs"
                         extensions=".csv,.txt">
          Associer des propriétés
        </app-upload-file>

        <button type="button" mat-raised-button (click)="clear_data()" style="margin-left: 20px;">
          Effacer tout
        </button>

      </div>

      <br><br>

      <div style="display: flex;flex-direction: row;justify-content: center;width: 90%;flex-wrap: wrap;">

        <app-input label="Titre du NFT"
                   [width]="w_data_field"
                   help="le champs __idx__ permet d'intégrer le numéro du NFT"
                   [value]="sel_config.data.title"
                   (valueChange)="sel_config.data.title=$event">
        </app-input>

        <app-input label="Mots clés associés (tags)" [width]="w_data_field"
                   help="Séparer les tags par une virgule"
                   [value]="sel_config.data.tags"
                   (valueChange)="sel_config.data.tags=$event">
        </app-input>

        <app-input label="Description"
                   help="le champs __idx__ permet d'intégrer le numéro du NFT"
                   [value]="sel_config.data.description"
                   (focusout)="save_config()"
                   [width]="w_data_field"
                   (valueChange)="sel_config.data.description=$event">
        </app-input>


        <app-input *ngIf="!network.isElrond()" label="Symbole"
                   [value]="sel_config.data.symbol"
                   (focusout)="save_config()"
                   (valueChange)="sel_config.data.symbol=$event">
        </app-input>

        <app-input label="Propriétés / Attributs" [value]="sel_config!.data.properties"
                   (valueChange)="sel_config!.data.properties=$event" [width]="w_data_field"
                   (focusout)="save_config()"
                   help="Respectez le format est '<nom_attribut>=<valeur_attribut>' et un seul attribut par ligne"
                   [rows]="4"></app-input>

        <app-input label="Liens / Fichiers" help="Placer un seul lien par ligne" [width]="w_data_field"
                   (focusout)="save_config()"
                   [value]="sel_config!.data.files" (valueChange)="sel_config!.data.files=$event"
                   [rows]="4"></app-input>

        <app-input label="Créateurs et royalties associées" [value]="sel_config!.data.creators"
                   (valueChange)="sel_config!.data.creators=$event" [width]="w_data_field" (focusout)="save_config()"
                   help="Chaque ligne contient <adresse du créateur>=<pourcentage de royalties>%"
                   [rows]="4" ></app-input>
      </div>


    </mat-expansion-panel>

  </div>

  <!--  Options avancées-->
  <mat-expansion-panel *ngIf="user.advance_mode">
    <mat-expansion-panel-header>Options avancées</mat-expansion-panel-header>
      <div class="expansion-content">
        <mat-form-field style="fontsize:small">
          <mat-label>Palette</mat-label>
          <mat-select [(value)]="sel_palette" (selectionChange)="change_palette($event)">
            <mat-option *ngFor="let k of palette_names" [value]="palette[k]">
              {{k}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div id="showPalette">
          <div style="width: 20px;height:20px;display: inline-block" *ngFor="let color of sel_colors" [style.background-color]="color" ></div>
        </div>

        <mat-checkbox [(ngModel)]="config_with_image">Inclure les images</mat-checkbox>


        <!--     https://material.angular.io/components/list/overview       -->
        <app-input [options]="fontfiles" label="Liste des fontes disponibles"
                   [value]="sel_font" (valueChange)="sel_font=$event"
                   value_field="value" value_type="list"></app-input>

        <div [style.font-family]="sel_font">Voici un exemple d'utilisation de la fonte</div>

      </div>

  </mat-expansion-panel>

</div>

