<div class="mainform" style="text-align: center">
  <br>
  <div class="mat-headline-3">{{user.params.appname}}</div>
  <img [src]="logo" style="width: 80%;max-width: 400px;margin: 30px;"><br>


  <br><br>
  <button
          type="button" mat-raised-button color="primary"
          title="Fabriquer votre premier NFT"
          (click)="open_create()">
    Fabriquez vos NFTs
  </button>

</div>
