<div class="mainform">
  <div class="titleform">Pool de minage des NFTs</div>
  <app-hourglass [message]="message"></app-hourglass>
  <div id="cmdPanel" style="width: 100%;" *ngIf="message.length==0">
    <button type="button" mat-raised-button *ngIf="user.advance_mode"
            (click)="run()">
      Traitement
    </button>

    <button type="button" mat-raised-button mat-button
            (click)="reset_pool()">
      Reset
    </button>

    <button type="button" mat-raised-button *ngIf="user.advance_mode"
              (click)="export_pool()">
      Export
    </button>

    <button type="button" mat-raised-button *ngIf="user.advance_mode"
            (click)="refresh()">
      Actualiser
    </button>

    <mat-checkbox *ngIf="asks.length>=10"
                  [(ngModel)]="showAll"
                  (ngModelChange)="refresh()">
      Tous les traitements
    </mat-checkbox>


    <br>

  </div>
  <table class="table-elevation">
    <tr>
      <th *ngIf="user.advance_mode">Ajouter le</th>
      <th>Sources</th>
      <th *ngIf="user.advance_mode">Filtre</th>
      <th>Network</th>
      <th>Mineur</th>
      <th>Collection</th>
      <th>Destinataire</th>
      <th>Prévu pour</th>
      <th>Réalisé le</th>
      <th>Résultat</th>
      <th>Commande</th>
    </tr>
    <tr *ngFor="let ask of asks">
      <td *ngIf="user.advance_mode">{{ask.dtCreate*1000 | date:'dd/MM HH:mm'}} </td>

      <td>
        <ul>
          <li *ngFor="let s of ask.sources">
            {{s.connexion}} / {{s.miner?.address}} {{s.collections}}
          </li>
        </ul>
      </td>
      <td *ngIf="user.advance_mode">{{ask.filter}}</td>
      <td>{{ask.target.network}} </td>
      <td style="max-width: 200px;"><app-link [content]="ask.target.miner" [network]="network.network"></app-link></td>
      <td style="max-width: 200px;">{{ask.target.collection}} </td>
      <td style="max-width: 200px;">{{ask.dest | alias | slice:0:20}} </td>
      <td>{{ask.dtStart*1000 | date:'dd/MM HH:mm'}}</td>
      <td><span *ngIf="ask.dtWork">{{ask.dtWork*1000 | date:'dd/MM HH:mm'}}</span></td>
      <td style="max-width: 200px;">{{ask.message | slice:0:40}}</td>
      <td>
        <button type="button" mat-icon-button *ngIf="!ask.dtWork"
                title="supprimer le traitement"
                (click)="cancel(ask)">
          <mat-icon>delete</mat-icon>
        </button>
        <button type="button" mat-icon-button *ngIf="!ask.dtWork"
                title="Exécuter le traitement"
                (click)="run(ask.id)">
          <mat-icon>play_circle</mat-icon>
        </button>

        <button type="button" mat-icon-button
                title="Recommencer le traitement"
                *ngIf="ask.dtWork"
                (click)="retry(ask)">
          <mat-icon>refresh</mat-icon>
        </button>

        <button type="button" mat-icon-button
                title="Voir le traitement"
                *ngIf="ask.dtWork && ask.message.indexOf('Transaction=')>-1"
                (click)="open_transaction(ask)">
          <mat-icon>visibility</mat-icon>
        </button>

      </td>
    </tr>
  </table>
  <br>
  <span>Localisation: {{network.config["DB_SERVER_PUBLIC"]}}</span>
  <app-signature style="position: absolute;display: block; bottom: 5px;width: 350px;left: 5px;" faq="minerpool"></app-signature>
</div>
