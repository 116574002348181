<app-hourglass [message]="message" [modal]="true" fontsize="xx-large"></app-hourglass>

<div class="mainform">

  <div class="titleform">Minage des NFTs</div>
  <div class="subtitleform">
    Minage sur {{network.network}} prévu pour {{tokens.length}} NFTs assuré par {{sel_key?.name}}
  </div>


  <div *ngIf="user.advance_mode">
    <mat-expansion-panel style="margin:10px;" >
      <mat-expansion-panel-header>Traitement en masse</mat-expansion-panel-header>
      <div class="expansion-content">
        <a href="./assets/Assets.xlsm">Fichier De traitement en masse</a><br><br>

        <button type="button"  mat-raised-button
                [cdkCopyToClipboard]="nfts_to_clipboard()"
                title="Copier la liste des NFT à miner dans le presse-papier"
                (click)="confirm_clipboard()">
          Exporter pour Opération
        </button>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel style="margin:10px;" *ngIf="user.advance_mode">
      <mat-expansion-panel-header>Propriétés avancée</mat-expansion-panel-header>
      <div id="command_panel" style="display: inline-block;margin:10px;" *ngIf="tokens.length>0">
        <!--    <mat-checkbox [(ngModel)]="sign">Signer</mat-checkbox>-->

        <mat-checkbox *ngFor="let create_option of create_options" [(ngModel)]="create_option.value" style="margin: 10px;">
          {{create_option.label}}
        </mat-checkbox>

      </div>

      <app-input [options]="network.stockage_document_available" width="320px"
                 *ngIf="user.advance_mode"
                 label="Stockage des documents liés"
                 [value]="sel_platform_document"
                 (valueChange)="sel_platform_document=$event"></app-input>


    </mat-expansion-panel>



    <mat-expansion-panel style="margin:10px;" *ngIf="network.isFile()">
      <mat-expansion-panel-header>Confidentialité</mat-expansion-panel-header>
      <mat-slide-toggle [(ngModel)]="encrypt_nft">Crypter les NFTs pour le fichier d'operations</mat-slide-toggle>
    </mat-expansion-panel>
  </div>




  <mat-expansion-panel [expanded]="tokens.length==0">
    <mat-expansion-panel-header>Gestion des visuels</mat-expansion-panel-header>

    <div class="expansion-content">
      <app-upload-file (uploaded)="onFileSelected($event)"
                       width="200px" [maxsize]="10000000"
                       extensions=".webp,.png,.gif,.jpeg,.jpg,.json">
        Ajouter des<br>nouveaux NFTs
      </app-upload-file>

      <button type="button"
              *ngIf="tokens.length>0"
              mat-raised-button mat-button
              (click)="clear()">
        Enlever les NFTs
      </button>

      <app-input [options]="network.stockage_available"
                 width="220px" *ngIf="user.advance_mode"
                 label="Stockage visuels & metadata"
                 [value]="sel_platform"
                 (valueChange)="sel_platform=$event"></app-input>


      <button type="button" *ngIf="tokens.length>0"
              mat-raised-button mat-button
              style="margin-left: 50px;"
              title="Mettre les visuels en lignes (en vue du minage)"
              (click)="upload_all_tokens()">
        <div class="bloc-button">
          Mettre en ligne<br>les visuels
        </div>
      </button>

      <button type="button" *ngIf="tokens.length>0"
              mat-raised-button mat-button
              (click)="export_to_csv()">
        Exporter sous Excel
      </button>

      <app-upload-file (uploaded)="batch_import($event)" *ngIf="tokens.length==0"
                       width="100px" [maxsize]="100000000"
                       extensions=".xlsx,.xls,.csv">
        Importer Excel
      </app-upload-file>
    </div>

  </mat-expansion-panel>


  <mat-expansion-panel *ngIf="tokens.length>0" [expanded]="show_mint_section" style="text-align: left;">
    <mat-expansion-panel-header>Lancement du minage</mat-expansion-panel-header>

    <div class="expansion-content">

      <button type="button" [disabled]="mintable()==0"
              mat-raised-button *ngIf="tokens.length>0"
              color="primary" style="margin: 50px;"
              (click)="confirm_mint(0,mintable())">
        Miner {{mintable()}} NFTs
      </button>

      <app-input
              label="Blockchain cible"
              title="Désigne la blockchain recevant les NFTs minés (Devnet signifie qu'il s'agit du réseau de test)"
              [options]="networks" width="200px"
              value_type="list" color="white"
              [value]="network.network" value_field="value"
              (valueChange)="updateChain($event)"></app-input>

      <app-selkey *ngIf="network.network"
                  style="width: 350px;"
                  [key]="sel_key"
                  label="Sélectionnez un mineur"
                  (onChange)="update_miner($event)"
                  [network]="network.network"></app-selkey>

      <div *ngIf="sel_key && (network.isElrond() || network.isDatabase())" style="width: 300px;">
        <app-input label="Collection utilisée"
                   [value]="sel_collection" width="225px"
                   (valueChange)="changeCollection($event)" value_type="list"
                   [options]="collections">
        </app-input>

        <button type="button" mat-icon-button
                title="Créer une nouvelle collection"
                (click)="new_collection()">
          <mat-icon>add_circle</mat-icon>
        </button>

        <button type="button" mat-icon-button
                title="Copier l'identifiant de la collection sélectionnée"
                *ngIf="sel_collection"
                [cdkCopyToClipboard]="sel_collection.id"
                (click)="informe_copy_collection()">
          <mat-icon>content_copy</mat-icon>
        </button>



        <!--        <app-warning help="Le minage de NFT sur Elrond nécessite d'avoir choisi une collection"-->
        <!--                     [if]="network.isElrond() && !sel_collection"></app-warning>-->

      </div>
      <app-input label="Nom de la collection"
                 *ngIf="network.isPolygon()"
                 [value]="collection_name" width="300px"
                 (valueChange)="collection_name=$event;local_save();">
      </app-input>
    </div>

  </mat-expansion-panel>


  <mat-expansion-panel *ngIf="tokens.length>0">
    <mat-expansion-panel-header>Créateurs</mat-expansion-panel-header>
    <div class="expansion-content">

      <div *ngIf="token_creators.length==1">
        <strong>Créateurs</strong>: {{token_creators[0].address | alias:network.network}}
      </div>

      <table style="display: inline-block;" *ngIf="token_creators.length>1">
        <tr *ngFor="let creator of token_creators">
          <td></td>
          <td style="text-align: right;" [title]="creator.address">{{(creator.address | alias:network.network).substring(0,15)+(creator.address.length>15 ? '..' : '')}}</td>
          <td><mat-slider [min]="0" [max]="100"><input matSliderThumb [(ngModel)]="creator.share" (valueChange)="recalc_rate_except(creator)"></mat-slider></td>
          <td>{{creator.share}}%</td>
          <td>
            <mat-icon (click)="remove_creator(creator)" style="cursor: pointer;font-size: medium;">cancel</mat-icon>
          </td>
        </tr>
      </table>

      <button type="button" *ngIf="!isInCreators(token_creators)"
              mat-raised-button [disabled]="!sel_key"
              (click)="add_miner_to_creator()">
        Ajouter le mineur
      </button>

      <button type="button"
              mat-raised-button mat-button
              (click)="ask_to_add_creator()">
        Ajouter un créateur
      </button>



    </div>



  </mat-expansion-panel>



  <div *ngIf="user.advance_mode">
    <button type="button"
            mat-raised-button mat-button
            (click)="ask_to_add_creator()">
      <mat-icon>add_circle</mat-icon>&nbsp;Créateur
    </button>

    <button type="button"
            mat-raised-button mat-button
            (click)="download_all()">
      Enregistrer
    </button>


    <button type="button" mat-raised-button mat-button
            title="Synchronize les paramètres sur l'operation sélectionnée"
            (click)="sync_on_ope()">
      Depuis l'opération
    </button>

  </div>




  <div *ngIf="content_for_clipboard.length>0">
    <h5>Copier ce texte pour l'insérer dans une opération</h5>
    <textarea style="margin-left: 10%;width: 90%;height: 400px;border: none;" class="mat-elevation-z2"
              (keydown)="clear_content_for_clipboard($event)">{{content_for_clipboard}}
    </textarea>
  </div>


  <br><br>

  <table style="padding: 5px;width:98%;border-spacing:0;text-align: center;overflow: scroll;" *ngIf="tokens.length>0" >
    <tr class="mat-headline-6">
      <th>Visuel</th>
      <th style="width: 75px;">
        Titre / Tags
        <mat-icon title="recopie les attributs du premier NFT sur les autres"
                  style="cursor: pointer;font-size: medium;"
                  (click)="clone_name()">refresh</mat-icon>

      </th>
      <th style="width: 75px;">Description</th>
      <th>
        Fichiers / Liens
      </th>
      <th>
        Price / Quantity / Royalties
        <mat-icon title="recopie les attributs du premier NFT sur les autres"
                  style="cursor: pointer;font-size: medium;"
                  (click)="clone_marketplace()">refresh</mat-icon>

      </th>
      <th style="vertical-align: center;width: fit-content;">
        Attributs
        <mat-icon title="recopie les attributs du premier NFT sur les autres"
                  style="cursor: pointer;font-size: medium;"
                  (click)="clone_attribute()">refresh</mat-icon>
        <mat-icon title="Ajouter un attribut"
                  style="cursor: pointer;font-size: medium;"
                  (click)="add_attribute()">add_circle</mat-icon>
        <mat-icon title="Effacer les attributs"
                  style="cursor: pointer;font-size: medium;"
                  (click)="clear_attribute()">clear</mat-icon>
      </th>

      <th>Commandes</th>

      <th style="min-width: 200px;">Message</th>
    </tr>
    <tr *ngFor="let token of tokens" style="margin: 2px;vertical-align: top;">
      <td style="width: fit-content">
        <div style="width: 125px;height: 125px;display: inline-block;margin:3px;">
          <a [href]="token.visual" target="_blank">
            <img [src]="token.visual"
                 [title]="token.visual"
                 alt="En cours de chargement ..." style="width: 100%;">
          </a>
        </div>
      </td>
      <td>
        <app-input label="Nom" [value]="token.name" (valueChange)="token.name=$event" [showClear]="false" width="175px"></app-input>
        <app-input label="Tags" [value]="token.tags" (valueChange)="token.tags=$event" width="175px"></app-input>

        <app-input *ngIf="!network.isElrond()" label="Symbol" [value]="token.symbol" (valueChange)="token.symbol=$event"></app-input>
      </td>
      <td>
        <app-input value_type="memo" width="175px" [showClear]="false"
                   [rows]="7" [cols]="15" label="Description" [value]="token.description" (valueChange)="token.description=$event"></app-input>
      </td>

      <td style="max-width: 200px;" >
        <div appFileDragNDrop (filesChangeEmiter)="drop($event,token)"
             title="Déposez vos fichiers à embarquer dans le NFT ici"
             style="min-height: 100px;width: 90%;margin: 5%;text-align: left;">
          <div *ngIf="token.files.length==0" style="display:inline-block;text-align: center;width:100%;"><br>Déposer vos fichiers ici</div>
          <div *ngFor="let file of token.files" class="mat-body-1" style="margin:0;width: 100%;overflow: hidden;">
            <small style="display: flex;flex-wrap: nowrap;justify-content: left;">
              <mat-icon style="font-size: medium;display: inline;cursor: pointer;margin-left:3px;" (click)="open_link(token,file)" [title]="file">visibility</mat-icon>
              <mat-icon style="font-size: medium;display: inline;cursor: pointer;margin-left: -6px;"
                        title="Détacher le document du NFT"
                        (click)="delete_link(token,file)">cancel</mat-icon>
              <div (dblclick)="open_document(file)" style="margin-left: -5px;" [title]="file">
                {{show_file(file).substring(0,20)}}
              </div>
            </small>
          </div>
        </div>
      </td>

      <td style="max-width: 50px;text-align: center;">
        <table style="text-align: center;display: inline-block;">
          <tr>
            <td>P =</td>
            <td><input matInput type="number" style="max-width: 50px;text-align: right;" min="0" [(ngModel)]="token.price" ></td>
            <td>€</td>
            <td style="width: 25px"></td>
          </tr>
          <tr *ngIf="!network.isElrond() || (sel_collection?.type=='SemiFungibleESDT' || sel_collection?.type=='NFT')">
            <td>Q =</td>
            <td><input matInput type="number" style="max-width: 50px;text-align: right;"  min="0" [(ngModel)]="token.supply" ></td>
            <td style="width: 25px"></td>
          </tr>
          <tr>
            <td>R =</td>
            <td><input matInput type="number" style="max-width: 50px;text-align: right;"  min="0" max="100" [(ngModel)]="token.royalties" ></td>
            <td>
              %
            </td>
            <td style="width: 25px">
              <!--              <app-warning help="En l'état les créateurs ne reçoivent aucune royalties"-->
              <!--                           [if]="token.creators.length>0 && token.royalties==0"></app-warning>-->
            </td>
          </tr>
        </table>

        <br>


      </td>

      <td style="text-align: left;max-width: 150px;">
        <ul>
          <li *ngFor="let a of token.attributes" (click)="edit_attribute(a)" style="cursor: pointer;">
            <strong *ngIf="a['trait_type'] && a['trait_type'].length>0">{{a['trait_type']}}:</strong> {{a.value}}
          </li>
        </ul>
      </td>



      <td>
        <mat-icon style="cursor: pointer" (click)="upload_token(token)"
                  title="upload du visuel"
                  *ngIf="token.visual.startsWith('data')">upload</mat-icon>
        <mat-icon style="cursor: pointer" (click)="confirm_mint(tokens.indexOf(token),1)"
                  title="minage du NFT"
                  *ngIf="isValide(token)=='' && !token.address">build</mat-icon>
        <mat-icon style="cursor: pointer" *ngIf="token.address && token.address.length>0"
                  title="Voir la transaction"
                  (click)="see_in_hub(token)">visibility</mat-icon>
        <mat-icon style="cursor: pointer" (click)="remove(token)">delete</mat-icon>
        <app-upload-file title="Associer un fichier d'attributs"
                         *ngIf="sel_key && !token.address" width="50px" style="display: inline-block;"
                         (uploaded)="onUploadXMP($event,token)"
                         extensions=".xmp" icon="note"></app-upload-file>
      </td>

      <td style="width: 100px;font-size: small;">
        <mat-icon style="cursor: pointer" *ngIf="!token.address && isValide(token)!=''" [title]="isValide(token)">warning</mat-icon>
        <div *ngIf="token.message">
          <app-hourglass *ngIf="token.message.startsWith('hourglass')" [message]="token.message.substring(10) || ''"></app-hourglass>
          <span *ngIf="!token.message.startsWith('hourglass')">{{token.message.substring(20)}}</span>
        </div>
      </td>

    </tr>
  </table>



</div>
