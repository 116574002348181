<div class="mainform" style="text-align: center" [ngStyle]="_style">
  <div *ngIf="!showEnd && nfts.length>0">
    <br>
    <h2 class="color-primary" *ngIf="!airdrop">{{title}}</h2>
    <h2 class="color-primary" *ngIf="airdrop">Airdrop de NFT</h2>

    <app-showroom [nfts]="nfts" [delay]="0.3" size="300px"></app-showroom>

    <br><br><br>


    <div *ngIf="airdrop">
      <br><br>
      <textarea class="mat-elevation-z2" *ngIf="airdrop"
                placeholder="Ecrivez/ Collez ici la liste des adresses (blockchaine ou email) de vos destinataires"
                title="Le format est 'nom_attribut=valeur' avec un attribut par ligne"
                matInput [(ngModel)]="addresses" rows="20" cols="40"></textarea>

      <br><br>
      <button type="button" mat-raised-button mat-button
              (click)="start_airdrop()"
              color="primary">
        Démarrer
      </button>
    </div>

    <div *ngIf="operation && operation.candymachine && !airdrop">
      <br><br>
      <h3>{{operation.candymachine.messages.question}}</h3>
      <app-authent
        [connexion]="operation.candymachine.connexion"
        (invalid)="invalid()"
        (authent)="authent($event)">
      </app-authent>
    </div>

    <div *ngIf="!operation && !airdrop">
      <app-authent
              [connexion]="connexion"
              (invalid)="invalid()"
              (authent)="authent($event)">
      </app-authent>
    </div>


  </div>

  <div *ngIf="showEnd" style="margin-top: 100px;">

    <p style="font-size: medium;">
      Votre demande de NFT est en cours de traitement. Cela peut prendre de 1 à 5 minutes<br>
      <span *ngIf="is_email">Un mail vous sera envoyé dès la fin de traitement.</span>
      <span *ngIf="!is_email">Rafraichissez votre wallet dans 1 à 2 minutes</span>
      <br><br>

      <strong>Vous pouvez maintenant fermer cette fenêtre</strong>
    </p>

  </div>

  <app-signature style="position: absolute;display: block; bottom: 5px;width: 350px;left: 5px;" faq="candymachine"></app-signature>
</div>
