<app-splash
            [appname]="appname"
            [duration]="2500"
            [image]="visual"
            [description]="claim"></app-splash>

<mat-toolbar class="mat-body-2" color="primary" *ngIf="user.toolbar_visible">
  <mat-icon (click)="drawer!.toggle()" *ngIf="device.isHandset$ | async">menu</mat-icon>

  <div *ngIf="!(device.isHandset$ | async) && network_service.online" style="margin-left: 10px;">
      {{user!.params!.appname}}<br>
      <small *ngIf="network_service.config.version?.length>0">v{{network_service.config.version | async}}</small>
  </div>

  <div *ngIf="!network_service.online">Connexion serveur interompu</div>

  <div *ngIf="network_service.waiting.length>0" style="display: inline-block;color:white;" class="mat-headline-6">
    <mat-spinner *ngIf="!network_service.waiting.startsWith('!')" style="display: inline-block;" color="warn" diameter="20"></mat-spinner>
    <mat-icon *ngIf="network_service.waiting.startsWith('!')">danger</mat-icon>
    {{network_service.waiting}}
  </div>

  <span class="spacer"></span>

  <div style="width: 60%;max-width:600px;display: flex;justify-content:flex-end;align-content: center;">

<!--    <app-input [value]="sel_network" style="margin: 5px;"-->
<!--               [options]="networks"-->
<!--               value_type="list" color="white"-->
<!--               (valueChange)="update_network($event)"></app-input>-->

<!--    <app-input value_type="list" color="white" style="margin: 5px;"-->
<!--               [options]="network_service.keys" value_field="address"-->
<!--               [value]="sel_addr"-->
<!--               (valueChange)="set_key($event)">-->
<!--    </app-input>-->

    <div style="display: inline-block;padding: 5px;">
      <mat-icon *ngIf="!(device.isHandset$ | async)" [cdkCopyToClipboard]="user.addr"
                title="Copier l'adresse publique"
                (click)="informe_copy()" style="font-size: x-large;">content_copy</mat-icon>
      <mat-icon  *ngIf="!(device.isHandset$ | async)" style="font-size: x-large;" (click)="open_wallet()">local_atm</mat-icon>
    </div>

  </div>
</mat-toolbar>


<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav sidemenu"
               [attr.role]="(device.isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(device.isHandset$ | async) ? 'over' : 'side'"
               [opened]="(device.isHandset$ | async) === false && user.toolbar_visible">

    <app-menu [items]="items" [status]="status"
              [with_label]="full_menu"
              (mouseup)="close_menu()"
              (select)="menuSelect($event)"></app-menu>
    <br><br>

    <div style="width:100%;text-align: left;margin:20px;">
      <mat-slide-toggle color="primary" (ngModelChange)="save_mode($event)" style="display: inline;"
                        [(ngModel)]="user.advance_mode">
        <small style="color: white;" *ngIf="full_menu">Avancé</small>
      </mat-slide-toggle>
<br><br>
     <mat-slide-toggle color="primary" [(ngModel)]="user.theme_mode" (ngModelChange)="theme_mode($event)" style="display: inline;">
        <small style="color: white;" *ngIf="full_menu">Dark mode</small>
      </mat-slide-toggle>

    </div>

  </mat-sidenav>


  <mat-sidenav-content>
    <router-outlet>
    </router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>


