<div class="mainform" [ngStyle]="operation?.branding?.style">

  <div class="titleform">{{operation?.title}}</div>

  <app-hourglass [message]="message"></app-hourglass>

  <div *ngIf="network_dest=='' || !miner_dest || !collection_dest" class="expansion-content">
    <app-input label="Collection utilisée"
               [value]="collection_dest" width="250px"
               (valueChange)="collection_dest=$event" value_type="list"
               [options]="collections">
    </app-input>

  </div>

  <div *ngIf="message.length==0" style="width: 100%;font-size: 15px;text-align: center;">
<!--    <div class="mat-headline-5 color-accent" *ngIf="miner">{{miner.address | alias}} cliquez sur le NFT à envoyer</div>-->
    <br>

    <mat-expansion-panel [expanded]="false" style="max-width: 1000px;display: inline-block;" *ngIf="operation?.dispenser?.mailing_list">
      <mat-expansion-panel-header>Traitement en masse</mat-expansion-panel-header>

      <button
              mat-raised-button mat-button
              title="Copier l'ensemble des NFTs"
              (click)="get_all_nfts()">
        Liste des identifiants
      </button>

      <app-upload-file (uploaded)="on_upload($event)" icon=""
                       title="Charger une liste de diffusion au format CSV/Excel"
                       extensions=".csv,.xlsx,.xls">
        Importer une liste de diffusion
      </app-upload-file>

    </mat-expansion-panel>
    <br>


    <div *ngFor="let nft of nfts"
         style="display: inline-block;margin:35px;cursor: pointer;max-width: 250px;"
         [title]="nft.message"
         [style]="nft.style">

      <div class="mat-body-2 color-primary" style="margin:5px;">{{nft.name}}</div>

      <app-reversebloc [image]="nft.visual" width="300px" height="300px" border_color="lightgray">
        <div style="padding: 1%;color: black;overflow: hidden;" class="mat-body-1">
          <br>
          <table>
            <tr *ngIf="user.advance_mode" [cdkCopyToClipboard]="nft.address!"  style="cursor: copy;">
              <td>Adresse</td>
              <td>{{nft.address!.split("/")[0]}}</td>
            </tr>

            <tr *ngIf="nft.collection">
              <td>Collection</td><td><span [title]="nft.collection!.description">{{nft.collection!.name}}</span></td>
            </tr>

            <tr *ngIf="nft.balances[nft.miner.address]>=1">
              <td>Quantité<br>Price</td>
              <td>{{nft.balances[nft.miner.address]}}<br>{{nft.price}}</td>
            </tr>

            <tr></tr>
            <tr *ngIf="user.advance_mode">
              <td>Mineur</td>
              <td>{{nft.miner.address | alias}}</td>
            </tr>

            <tr *ngIf="user.advance_mode">
              <td>Network</td>
              <td>{{nft.network}}</td>
            </tr>

            <tr *ngIf="nft.attributes.length>0">
              <td>
                Propriétés
              </td>
              <td>
                {{nft.attributes.join('\n')}}
              </td>
            </tr>
            <br>
            <tr *ngIf="nft.creators.length>0" style="width: 100%;">
              <td>
                Créateurs<br>
                <table style="width: 90%;overflow: hidden;">
                  <tr  *ngFor="let creator of nft.creators">
                    <td>{{creator.address | alias}}</td><td style="text-align: right;">:{{creator.share}}%</td>
                  </tr>
                </table>
            </tr>
          </table>
        </div>

        <div style="display: block;position:absolute;bottom: 10px;text-align: right;left:0px;width: 95%;">
          <button color="primary"
                  mat-raised-button mat-button
                  title="Envoyer le NFT à un destinataire"
                  (click)="send(nft)">
            Envoyer
          </button>

          <button color="primary"
                  mat-raised-button mat-button
                  title="Lien de récupération du NFT par le propriétaire"
                  (click)="copy_link(nft)">
            Copier
          </button>

        </div>

      </app-reversebloc>

      <br>

    </div>

    <div *ngIf="nfts.length==0">
      Aucun NFT à distribuer
    </div>

  </div>

  <br>

  <app-signature style="position: absolute;display: block; bottom: 5px;width: 350px;left: 5px;" faq="dispenser"></app-signature>
</div>
