  <div id="toolbar"
       *ngIf="filter && filter[selFilter] && props && props.length>0"
       class="toolbar-app">

    <mat-form-field appearance="outline">
      <mat-label>Propriétés</mat-label>
      <mat-select id="selSelFilter" [(ngModel)]="selFilter">
        <mat-option *ngFor="let p of props" [value]="p">
          {{p}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slider
      [title]="selFilter"
      color="primary"
      class="app-slider"

      (valueChange)="updateForces(width,height)"
      [min]="filter[selFilter].min" [max]="filter[selFilter].max" [step]="filter[selFilter].step">
        <input matSliderThumb [(value)]="filter[selFilter].value">
    </mat-slider>


<!--    <mat-form-field appearance="outline">-->
<!--      <mat-select id="selPromoFilter" [(ngModel)]="filter.promo.value" (selectionChange)="updateData()">-->
<!--        <mat-option *ngFor="let y of filter.promo.values" [value]="y">-->
<!--          {{y}}-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->

<!--    <mat-form-field appearance="outline">-->
<!--      <mat-select id="selFormationFilter" [(ngModel)]="filter.department.value" (selectionChange)="updateData()">-->
<!--        <mat-option *ngFor="let y of filter.department.values" [value]="y">-->
<!--          {{y}}-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->

    <mat-icon (click)="clear_filter()" style="cursor: pointer;">cancel</mat-icon>

    <div *ngIf="sel_node"
         style="display: inline-block;margin:5px;line-height: 90%;"
         [innerHTML]="sel_node.label">
    </div>

  </div>


<app-hourglass [message]="message"></app-hourglass>
<figure #graph_zone
        *ngIf="data"
        id="graph_zone" name="graph_zone"
        style="width: 90%;height:100%;background-color: lightgray;">
</figure>

