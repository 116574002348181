<div class="mainform" [ngStyle]="operation?.branding?.style">
  <div class="titleform" *ngIf="title!.length>0">{{title}}</div>
  <div *ngIf="subtitle!.length>0">{{subtitle}}</div>
  <br>
  <app-hourglass [message]="message"></app-hourglass>

  <div *ngIf="message?.length==0">
    <app-reversebloc *ngFor="let nft of nfts" [image]="nft.visual"
                     [fontsize]="operation?.store?.apparence?.fontsize || '12px'"
                     [width]="operation?.store?.apparence?.size || '200px'"
                     [height]="operation?.store?.apparence?.size || '200px'">

      <div [ngStyle]="style_reverse_card">
        <div *ngIf="nft.collection" [style.font-size]="fontsize+'px'">{{nft.collection.name}}</div>
        <div style="margin-top: 10px"
             [style.font-size]="2*fontsize+'px'">
          {{nft.name}}
        </div>

        <button type="button" mat-raised-button color="primary" style="display: inline-block;position: absolute;right: 20px;bottom: 20px;"
                (click)="buy(nft)">
          <span *ngIf="nft.price>0">Acheter {{nft.price}}€</span>
          <span *ngIf="nft.price==0">Acquérir</span>
        </button>
      </div>

    </app-reversebloc>

    <div *ngIf="nfts?.length==0">Aucun NFT à vendre</div>
  </div>
  <app-signature faq="store"></app-signature>
</div>
