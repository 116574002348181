<div class="mainform">
  <mat-icon (click)="refresh()">refresh</mat-icon>

  <mat-form-field appearance="fill" style="width:120px">
    <mat-label>Recherche par</mat-label>
    <mat-select  [(value)]="type_addr" (selectionChange)="change_typeaddr($event)" >
      <mat-option value="miner">Mineur</mat-option>
      <mat-option value="owner">Propriétaire</mat-option>
      <mat-option value="token">Token</mat-option>
      <mat-option value="token_list">Liste</mat-option>
      <mat-option value="id">Identifiant</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="type_addr=='token_list' && addrs.length==0" style="width:200px;display: inline-block;" class="mat-elevation-z4">
  <textarea (paste)="paste_list($event)" rows="2"
            style="display: inline;"
            placeholder="Coller vos adresses ici"
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5">
  </textarea>

  </div>


  <button type="button" mat-raised-button mat-button
          *ngIf="type_addr=='token_list' && addrs.length>0"
          (click)="addrs=[]">
    Change List
  </button>



  <mat-form-field appearance="fill" style="width: 375px" *ngIf="type_addr!='token_list'">
    <mat-label>Addresse ou pseudo</mat-label>
    <input matInput type="text" maxlength="64" [(ngModel)]="address" (keypress)="onkeypress($event)" >
    <mat-icon *ngIf="address!=''" matSuffix style="cursor: pointer" (click)="clear_pubkey()">cancel</mat-icon>
    <mat-hint>L'adresse correspond à la clé publique</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="fill" style="width:150px">
    <mat-label>Dans la collection</mat-label>
    <input matInput type="text" maxlength="64" [(ngModel)]="search_collection">
  </mat-form-field>

  <mat-form-field appearance="fill" style="width:150px">
    <mat-label>Dans les métadonnées</mat-label>
    <input matInput type="text" maxlength="64" [(ngModel)]="search_metadata" >
  </mat-form-field>

  &nbsp;<mat-form-field appearance="fill" style="width:70px">
    <mat-label>Limite</mat-label>
    <input matInput type="number" maxlength="64" [(ngModel)]="limit" >
  </mat-form-field>

  &nbsp;
  <button type="button" mat-raised-button mat-button
          (click)="refresh()">
    Rechercher
  </button>

  <button type="button" mat-raised-button mat-button
          [disabled]="nfts?.length==0"
          (click)="burn_all()">
    Reset
  </button>

  <button type="button" mat-raised-button mat-button
          [disabled]="nfts?.length==0"
          (click)="transfer_all()">
    Transfert vers {{user.key?.name}}
  </button>

  <br>
  <div *ngIf="nfts!.length>0">{{nfts.length}} NFT trouvé(s)<br></div>
  <app-nfts [nfts]="nfts | filter: ['collection',search_collection] | filter: ['metadata',search_metadata]" format="obj"
            (refresh)="refresh()"
            [user]="user.key?.address">
  </app-nfts>

</div>
