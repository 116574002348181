<div class="mainform" *ngIf="operation && operation.validate" [ngStyle]="operation?.branding?.style">
  <div *ngIf="user!.length>0 && operation && operation.validate.users.length>0">
<!--    Ecran destiné aux validateur pour authentifier les visiteurs-->
    <div *ngIf="operation.validate && operation.validate!.visible && result_message?.length==0">
      <h2>{{operation.title}}</h2><br><br>
      <div *ngIf="message?.length==0 && tokens.length==0">

        <br>
        <span>{{operation.validate?.support?.message_search}}</span>

        <div *ngIf="operation.validate?.manual_input">
          <mat-form-field appearance="fill" style="min-width: 350px;font-size: medium;width: 50%;">
            <mat-label>Adresse du propriétaire</mat-label>
            <input matInput type="text" [(ngModel)]="query" cdkFocusInitial (keypress)="check_addr($event)" autofocus>
            <mat-icon matSuffix (click)="query=''">cancel</mat-icon>
          </mat-form-field>
          <br>
          <button type="button" mat-raised-button (click)="check_addr({keyCode:13})">
            Rechercher
          </button>
        </div>

        <br><br><br>

        <app-scanner *ngIf="operation.validate.camera" (flash)="onflash($event)"></app-scanner>
      </div>

      <br>
      <app-hourglass [message]="message"></app-hourglass>

      <div *ngIf="message!.length>0 && tokens.length==0">
        <br><br>
        <button
          style="margin: 5px"
          type="button" mat-raised-button mat-button
          (click)="message=''">
          Annuler
        </button>
      </div>


      <div *ngIf="tokens.length>0">
        <div style="width:100%;text-align: center;font-size: small;">
          <div class="mat-headline-6">{{operation.validate?.actions?.success!.message}}</div>

          <table style="padding: 10px;width:90%;margin:5%;" class="mat-elevation-z4" *ngIf="screen=='landscape'">
            <tr>
              <th>Nom du NFT</th>
              <th *ngFor="let idx of attributes_to_show[0]">
                {{tokens[0].attributes[idx]?.trait_type}}
              </th>
              <th *ngIf="operation.validate.actions!.buttons!.length>0">
                Commandes<br>disponibles
              </th>
            </tr>
            <tr *ngFor="let t of tokens">
              <td *ngIf="t.name">
                {{t?.name}}<br>
                <img [src]="t?.visual" style="width: 150px;">
              </td>
              <td *ngFor="let idx of attributes_to_show[0]">
                <span style="font-size: x-large">{{t.attributes[idx]?.value}}</span>
              </td>
              <td>
                <button *ngFor="let action of operation.validate.actions.buttons"
                        style="margin: 5px"
                        type="button" mat-raised-button mat-button
                        (click)="validate(t,action)">
                  {{action.label}}
                </button>
              </td>
            </tr>
          </table>


          <table style="padding: 5px;width:98%;margin:1%;" class="mat-elevation-z4" *ngIf="screen!='landscape'">
            <tr>
              <th>NFT</th>
              <th *ngFor="let t of tokens" (dblclick)="open_token(t)">
                {{t?.name}}<br>
                <img [src]="t?.visual" style="width: 100px;" class="mat-elevation-z4">
              </th>

            </tr>
            <tr *ngFor="let idx of operation.validate.properties">
              <th>{{idx}}</th>
              <td *ngFor="let t of tokens">
                <span style="font-size: large">{{show_attribute(t,idx)}}</span>
              </td>
            </tr>

            <tr>
              <th *ngIf="operation.validate.actions!.buttons && operation.validate.actions!.buttons!.length>0">Commandes<br>disponibles</th>
              <td *ngFor="let t of tokens">
                <button *ngFor="let action of operation.validate.actions.buttons"
                        style="margin: 5px"
                        [disabled]="action.collections.length>0 && action.collections.indexOf(t.collection!.id!)==-1"
                        type="button" mat-raised-button mat-button
                        (click)="validate(t,action)">
                  {{action.label}}
                </button>
                <br>
              </td>
            </tr>
          </table>


        </div>
        <br>
        <button type="button" mat-raised-button
                mat-button (click)="cancel_validation()">
          Retour
        </button>
      </div>
    </div>


    <div *ngIf="result_message && result_message.length>0">
      <br>
      <div style="font-size: xx-large;width:100%;text-align: center;">
        {{result_message}}
      </div>
      <br>
      <mat-form-field appearance="fill" style="min-width: 300px;font-size: medium;width:70%;" *ngIf="status=='0'">
        <mat-label>Saisir l'email du client pour lui envoyer une confirmation</mat-label>
        <input matInput type="text" [(ngModel)]="email">
        <mat-icon matSuffix (click)="email=''">cancel</mat-icon>
      </mat-form-field>

      <br>
      <button type="button" mat-raised-button
              *ngIf="status=='0'"
              mat-button (click)="confirm_transac()">
        Envoyer
      </button>

      <button type="button" mat-raised-button
              mat-button (click)="confirm_transac()">
        Nouveau client
      </button>
    </div>
  </div>


  <div *ngIf="user?.length==0 && operation">
    <h2>{{operation.validate!.title}}</h2>
    <mat-form-field appearance="fill" style="min-width: 400px;font-size: medium;">
      <mat-label>Veuillez saisir votre code d'accès (ou votre mail si vous n'avez pas votre code)</mat-label>
      <input matInput type="text" [(ngModel)]="access_code" (ngModelChange)="update_access_code()">
      <mat-icon matSuffix (click)="access_code=''">cancel</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="show_warning_process && operation!.validate"
       [innerHTML]="operation.validate!.support!.warning_process"
       style="color:red;font-size: large;text-align: left;width:60%;margin:20%;">
  </div>



  <div style="position: absolute;bottom: 5%;text-align: center;width: 100%;" *ngIf="operation.validate">
    {{operation?.validate?.support?.contacts?.message}}<br>
    <a *ngIf="operation?.validate?.support?.contacts?.mail!=''"
       title="Envoyer un mail"
       [href]="operation?.validate?.support?.contacts?.mail">
      <mat-icon>mail</mat-icon>
    </a>
    <a *ngIf="operation?.validate?.support?.contacts?.phone"
       title="Contacter le support téléphonique"
       [href]="operation?.validate?.support!.contacts.phone">
      <mat-icon>phone</mat-icon></a>

    <a *ngIf="operation?.validate?.support?.contacts?.telegram!=''"
       title="Joindre le groupe télégram des utilisateurs"
       [href]="operation?.validate?.support?.contacts?.telegram">
      <mat-icon>send</mat-icon>
    </a>

    <mat-icon *ngIf="operation?.validate?.support?.warning_process!=''"
              title="En cas de problème technique, voir le processus de fonctionnement dégradé"
              (click)="show_warning_process=!show_warning_process"
              style="cursor: pointer;color:black;">
      build
    </mat-icon>
  </div>


</div>
