<div class="mainform">
  <div class="titleform">Liste des validateurs</div>
  <button type="button" mat-raised-button (click)="delete_all()">
    Tous supprimer
  </button>
  <button type="button" mat-raised-button (click)="refresh()">
    Actualiser
  </button>

  <br>
  <table style="width: 96%;text-align: center;padding: 1%;margin:1%;" class="mat-elevation-z4">
    <tr>
      <th>Id</th>
      <th>Last connexion</th>
      <th>Demandes</th>
      <th>Nombre de NFTs</th>
      <th>Code de validation</th>
      <th>Actions</th>
    </tr>
    <tr *ngFor="let validator of validators">
      <td>{{validator.id}}</td>
      <td>{{validator.dtStart | date:'dd/MM HH:mm'}} ({{validator.delayFromStart}})</td>

      <td>
<!--        <mat-select [(ngModel)]="validator.operation" (ngModelChange)="update_operation(validator)">-->
<!--          <mat-option *ngFor="let operation of operations" [value]="operation.id">{{operation.title}}</mat-option>-->
<!--        </mat-select>-->
<!--        {{validator.operation}}-->
        {{validator.ask}}
      </td>
      <td>{{validator.nfts}}</td>
      <td>
        <img [src]="validator.qrcode_accesscode"
             style="height:100px;width: 100px;cursor: copy"
             [cdkCopyToClipboard]="validator.access_code">

      </td>
      <td>
        <button type="button" mat-raised-button (click)="desactivate(validator)">Désactiver</button>
        <button type="button" mat-raised-button (click)="receive_nft(validator)">Obtenir NFT requis</button>
        <button type="button" mat-raised-button color="accent" (click)="delete(validator)">Supprimer</button>
      </td>

    </tr>
  </table>
  <app-signature style="position: absolute;display: block; bottom: 5px;width: 350px;left: 5px;" faq="validators"></app-signature>
</div>
