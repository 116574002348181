<div *ngIf="content" style="display: inline-block;">
  <div [title]="content"
       *ngIf="!content.startsWith('erd') && !content.startsWith('http') && !content.startsWith('mailto')">
    {{content.substring(0,20)+".."}}
  </div>

  <a *ngIf="content.startsWith('http') || content.startsWith('mailto')" target="_blank" [href]="content" [title]="content">
    <ng-content></ng-content>
  </a>

  <a *ngIf="content.startsWith('erd')" [href]="get_explorer(content)" [title]="content">
    {{content | alias | slice:0:20}}
  </a>
</div>
