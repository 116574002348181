<div class="background-form" [style.background-image]="'url('+background+')'">
	<div class="popup-form background-component mat-elevation-z18" [style.left]="border" [style.top]="border" [style.width]="size" [style.height]="size">
	<h1 style="margin-top: 20px;" *ngIf="bank">{{bank.title || ""}}</h1>
	<app-hourglass [message]="message"></app-hourglass>
	<app-jauge [activate]="message.length>0" [duration]="40" [step]="0.1" [max]="100"></app-jauge>
	<div *ngIf="!show_can_close && message.length==0 && bank">
		<h4 *ngIf="addr!='' && token">
			Des {{token.name}}
			<span *ngIf="network.isDevnet()">(devnet)</span>
			pour
			<span class="color-primary" *ngIf="addr.length>0">{{addr.substring(0,20)+"..."}}</span>
		</h4>
		<app-authent *ngIf="addr.length==0"
		             style="width: 400px;display: inline-block;"
		             title="Veuillez vous identifier"
		             [showNfluentWalletConnect]="true"
		             [showWalletConnect]="true"
		             [showWebWallet]="true"
		             [showAddress]="true"
					 [showEmail]="true"
					 [showGoogle]="true"
					 (cancel)="cancel()"
		             [showExtensionWallet]="true"
		             (authent)="update_address($event)">
		</app-authent>

		<div *ngIf="addr.length>0 && token" style="margin-top: 100px;">

			<div *ngIf="balance>0">
				Vous avez<br><br>
				<div class="color-primary mat-headline-3" style="margin-top: -10px;">{{balance}}</div>
				<div style="margin-top: -50px;">{{token!.name}}</div>

			</div>

			<div 		 *ngIf="show_payment" style="margin-top: 100px;">
				{{'Achat de '+bank.refund+' '+token.name}}
				<app-payment [merchant]="user.merchant"
							 [user]="addr"
							 buy_method="fiat"
							 [bank]="bank"
							 [fiat_price]="fiat_price*bank.refund"
							 (cancel)="cancel_pay()"
							 [title]="'Achat de '+bank.refund+' '+token.name"
							 (paid)="onpaid($event)">
				</app-payment>
			</div>


			<div  style="position:absolute;display:block;bottom: 15px;text-align: center;width:100%;padding:0;">
				<button mat-raised-button color="primary"
						*ngIf="fiat_price==0"
				        (click)="refund()">
					Recevoir<br>{{bank.refund}} {{token.name}}
				</button>

				<button mat-raised-button color="primary"
						*ngIf="fiat_price>0"
				        (click)="buy()">
					Acheter
				</button>

				<button mat-raised-button
				        (click)="change_addr()">
					Changer<br>d'adresse
				</button>

			</div>
		</div>

	</div>

	<div *ngIf="show_can_close">
		<br>
		Vous disposez de <span class="color-primary">{{balance}} {{token.name}}</span><br><br>
		<br><br>

		<h2>
			{{end_message}}<br>
			Vous pouvez maintenant fermer cet écran
		</h2>
		<br>
		<a *ngIf="transaction && transaction.explorer" [href]="transaction!.explorer" target="_blank">Voir la transaction</a>
		<br>
		<button mat-raised-button color="primary"
				style="margin-top: 60px;"
		        (click)="_location.back()">
			Retour
		</button>
	</div>


</div>
</div>
