<div class="mainform" style="height: 100vh;">
<!--
              L'integration des fonctionnalité de l'authent peut se faire directement dans le code sans passer par un fichier d'operation
               [checknft]="operation.validate.filters.collections"
               [showWalletConnect]="operation.validate.authentification.wallet_connect"
               [showAddress]="operation.validate.authentification.address"
               [showWebcam]="operation.validate.authentification.webcam"
               [showNfluentWalletConnect]="operation.validate.authentification.nfluent_wallet_connect"
-->

  <app-autovalidator title="Veuillez scanner le code"
                     *ngIf="authentification"
                     [showCollections]="true"
                     (validate)="success($event)"
                     (fail)="fail($event)"
                     [network]="network_name"
                     [validator_name]="validator_name"
                     [collections]="collections"
                     [connexion]="authentification">
  </app-autovalidator>

<!--  -->
<!--  <app-authent (authent)="on_authent($event)"-->
<!--               size="300px"-->
<!--               [directShowQRCode]="true"-->
<!--               *ngIf="operation && operation!.validate && operation!.validate.authentification && operation.validate.filters"-->
<!--               [connexion]="operation.validate.authentification"-->
<!--               (invalid)="on_invalid()"-->
<!--               (disconnect)="on_disconnect()">-->
<!--  </app-authent>-->

<!--  authentification sans operation -->
<!--  <app-authent (authent)="on_authent($event)" size="300px" *ngIf="!operation && collections && authentification"-->
<!--               [directShowQRCode]="true"-->
<!--               [connexion]="authentification"-->
<!--               (invalid)="on_invalid()"-->
<!--               (disconnect)="on_disconnect()">-->
<!--  </app-authent>-->



  <div style="font-size: large;width: 100%;text-align: center;margin-top: 50px;">
    {{message}}
  </div>

  <div style="position: absolute;display:block;bottom:10px;right:10px;width:150px;text-align:right;color: lightgray;">
    {{validator_name}}
  </div>

</div>
