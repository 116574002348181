
<table style="width: 100%">
  <tr >
    <td style="text-align: left;">{{rows.length}} enregistrements</td>
    <td style="text-align: right;"><button *ngIf="showClear && rows.length>0"  mat-raised-button color="warn" (click)="clear()">Clear</button></td>
  </tr>
</table>



<table mat-table style="text-align: center;width:100%;" [dataSource]="rows" [title]="table">
    <ng-container *ngFor="let col of cols" [matColumnDef]="col">
      <div *ngIf="!col.startsWith('i_')">
        <th mat-header-cell class="color-primary" *matHeaderCellDef >{{col}}</th>
      </div>
      <div *ngIf="col.startsWith('i_')">
        <th mat-header-cell *matHeaderCellDef [title]="col.split('|')[1]">
          <mat-icon style="margin-left:5px;">
            {{col.split('|')[0].replace('i_','')}}
          </mat-icon>
        </th>
      </div>
      <td mat-cell [title]="element[col]" *matCellDef="let element">{{truncate(element[col])}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row *matRowDef="let row; columns: cols;" style="width: 100%;"></tr>
  </table>


