<div class="input-elements" [style.width]="width" [style.max-width]="maxwidth" [ngClass]="fontname">

  <!-- input standard -->
  <mat-form-field appearance="fill"  [title]="help_input" style="width: 100%;padding: 0;"
                  *ngIf="value_type!='slider' && value_type!='list' && options.length==0 && cols==0 && rows==0">
    <mat-label *ngIf="label.length>0" class="mat-caption">{{label}}</mat-label>
    <input matInput
           [max]="max"
           [min]="min"
           [type]="value_type"
           [maxlength]="maxlength"
           [(ngModel)]="value"
           [placeholder]="placeholder"
           [title]="infobulle"
           (focusin)="showHelp=true" (focusout)="showHelp=false" (keyup)="on_key($event)">

    <mat-icon matSuffix *ngIf="showClear && value_type!='number' && value && value!.length>0"
              style="opacity: 0.6;"
              (click)="on_clear()">cancel</mat-icon>
    <mat-hint *ngIf="showHelp" class="mat-body-1 help-input">{{help}}</mat-hint>
  </mat-form-field>

  <!-- input de type textarea-->
  <mat-form-field style="width: 100%;" *ngIf="cols>0 && rows>0" appearance="fill">
    <mat-label *ngIf="label.length>0" class="mat-caption">{{label}}</mat-label>

    <textarea [placeholder]="placeholder" title="infobull" matInput
              [(ngModel)]="value"
              [rows]="rows" [cols]="cols" (focusin)="showHelp=true"
              (focusout)="showHelp=false" (keyup)="on_key($event)">
    </textarea>

    <mat-icon matSuffix *ngIf="showClear && value && value!.length>0" (click)="on_clear()" style="opacity: 0.7;">cancel</mat-icon>
    <mat-hint *ngIf="showHelp" class="mat-body-1 help-input">
      {{help}}
    </mat-hint>
  </mat-form-field>

  <button mat-raised-button mat-button
          *ngIf="label_button.length>0"
          style="height: 40px;margin-left:10px;"
          [disabled]="!value || value.length==0"
          [title]="help_button"
          (click)="on_validate()">
    {{label_button}}
  </button>

  <button mat-raised-button mat-button
          *ngIf="cancel_button.length>0"
          [title]="help_button"
          (click)="on_cancel()">
    {{cancel_button}}
  </button>

<!--options list-->
  <mat-form-field  style="width:100%;" appearance="fill"
                  *ngIf="value_type=='list' || options.length>0">

    <mat-label *ngIf="label.length>0" class="mat-caption">{{label}}</mat-label>
    <mat-select [(value)]="value" (selectionChange)="sel_change($event)" [multiple]="multiselect" >
      <mat-option *ngFor="let option of options" [value]="option.value" class="mat-body-2">
        {{option.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!--  Slider -->
  <div *ngIf="value_type=='slider' || value_type=='slide'" style="width: 100%;">
    <table *ngIf="label.length>0" style="width: 100%;"><tr>
      <td style="text-align: left;width:80%;"><span class="mat-caption">{{label}}</span></td>
      <td style="text-align: right;overflow: hidden;" class="mat-headline-6" [style.color]="color_value">
        <input [(ngModel)]="value" type="number" [max]="max" [min]="min" matInput
               class="mat-caption color-primary"
               (ngModelChange)="direct_change_slider()"
               (focusout)="direct_change_slider()"
               style="max-width:60px;border: none;background:none;font-size: large;text-align: right;">
      </td>
    </tr></table>
    <mat-slider [max]="max" [min]="min" [step]="step"
                 style="margin-left: -3px;width:95%;margin-top: -10px;" *ngIf="max>min"
                 (focusin)="showHelp=true" (focusout)="showHelp=false">
      <input matSliderThumb [(ngModel)]="value" (ngModelChange)="sel_change({value:$event})">
    </mat-slider>
    <div class="mat-body-1" style="margin-top:-5px;text-align: left;" [style.opacity]="showHelp ? 0.7 : 0">{{help}}</div>
  </div>

  <ng-content></ng-content>
</div>