<div class="mainform">
  <div class="titleform">Gestion des clés hébergées sur {{network.network}}</div>
  <div class="subtitleform">
    Pour le minage ou la distribution, TokenForge peut utiliser différents comptes.
    Ces comptes peuvent être public pour les tests et totalement privés et sécurisé pour la production
  </div>

  <mat-expansion-panel [expanded]="false">
    <mat-expansion-panel-header>Ouvrir un compte</mat-expansion-panel-header>
    <div class="expansion-content">
      <app-input label="Coller ici votre clé privée" width="350px"
                 [value]="privateKey" (valueChange)="privateKey=$event"
                 help="Plusieurs formats sont acceptés"></app-input>

      <app-input label="Alias de la clé" width="150px"
                 [value]="name" (valueChange)="name=$event"
                 help="Nom court" maxlength="20"></app-input>
      <button
              type="button" aria-label="Ajouter" mat-raised-button color="primary"
              [disabled]="name=='' || privateKey==''"
              title="Encrypter une clé pour intégration dans un fichier d'opération"
              (click)="encrypt({name:name,key:privateKey})">
        Crypter et copier
      </button>

      <button
              type="button" aria-label="Ajouter" mat-raised-button
              [disabled]="name=='' || privateKey==''" *ngIf="user.advance_mode"
              (click)="new_key()">
        <div class="bloc-bouton">Ajouter à<br>mes clés</div>
      </button>

      <button
              type="button" aria-label="Nouvelle" mat-raised-button
              title="Créer une nouvelle clé"
              (click)="create_key()">
        <div class="bloc-bouton">Créer une clés</div>
      </button>


    </div>


  </mat-expansion-panel>

  <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;">


    <button
            type="button" aria-label="Ajouter" mat-raised-button
            (click)="open_nfluent_wallet()">
      Wallet NFluent
    </button>

    <app-upload-file extensions=".key,.txt"
                     style="margin:10px;" width="300px"
                     (uploaded)="onuploaded_key_file($event)">
      Charger les<br>fichiers de clé
    </app-upload-file>

    <app-input
            label="Blockchain"
            [options]="network.networks_available" width="200px"
            value_type="list" color="white"
            [value]="network.network" value_field="value"
            (valueChange)="updateNetwork($event)"></app-input>
  </div>

  <br>
  <app-hourglass [message]="message"></app-hourglass>
  <h2>
    Liste des clés disponibles sur le serveur pour le {{network.network}}
    <mat-icon (click)="refresh()">refresh</mat-icon>

  </h2>


  <table class="table-elevation" style="text-align: left;width:95%;">
    <tr style="font-style: italic;font-size: large;">
      <th style="width:15%;text-align: center;">Alias</th>
      <th *ngIf="user.advance_mode">Public Key</th>
      <th *ngIf="user.advance_mode">QRCode</th>
      <th style="text-align: center;" *ngIf="!(device.isHandset$ | async)">Commandes</th>
      <th style="width: 75px;text-align: right;">Solde</th>
    </tr>
    <tr *ngFor="let key of network.keys">
      <td>
        <a [href]="key.explorer" target="_blank"><strong>{{key.name}}</strong></a>
      </td>
      <td style="font-size: small;" *ngIf="user.advance_mode">
        <a [href]="key.explorer">{{key.address.substring(0,5)+' ... '+key.address.substring(key.address.length-5)}}</a>

      </td>
      <td *ngIf="user.advance_mode">
        <img *ngIf="key.qrcode && key.qrcode!.length>0"
             [src]="key.qrcode" style="width: 100px;cursor: pointer;"
             title="cliquer pour copier l'adresse"
             [cdkCopyToClipboard]="key.address">
      </td>
      <td style="display: flex;flex-wrap: wrap;justify-content: center;" *ngIf="!(device.isHandset$ | async)">
        <button mat-icon-button (click)="del_key(key.name)" title="supprimer">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button  (click)="encrypt(key,false)" title="Encrypter la clé privée"><mat-icon>key</mat-icon></button>
        <button mat-icon-button  (click)="encrypt(key,true)" title="Encrypter toute la clé"><mat-icon>lock</mat-icon></button>
        <button mat-icon-button title="Copier l'adresse" [cdkCopyToClipboard]="key.address"><mat-icon>content_copy</mat-icon></button>
        <button title="voir le wallet" mat-icon-button (click)="open_wallet(key)"><mat-icon>money</mat-icon></button>
        <button title="voir le wallet" mat-icon-button (click)="burn_all_nft(key)"><mat-icon>local_fire_department</mat-icon></button>


        <button title="voir dans l'explorer" mat-icon-button (click)="open_explorer(key)">
          <mat-icon>person_search</mat-icon>
        </button>

        <button type="button" mat-icon-button (click)="open_gallery(key)">
          <mat-icon>photo</mat-icon>
        </button>

<!--        <button type="button" mat-raised-button (click)="open_lazy_wallet(key)">-->
<!--          Lazy-->
<!--        </button>-->

        <button type="button" mat-raised-button (click)="open_collections(key,'nfluent')">
          Collections
        </button>
        <button type="button" mat-raised-button (click)="open_collections(key,network.isElrond() ? 'inspire' : 'opensea' )">
          SpotLight
        </button>
        <button type="button" mat-raised-button mat-button
                [cdkCopyToClipboard]="key.address"
                (click)="open_faucet(key)">
          Recharger
        </button>
      </td>
      <td style="text-align: right;">
        <span *ngIf="key.balance">{{key.balance/1e18 | number}} {{key.unity}}</span>
      </td>
    </tr>
  </table>

  <br>
  <p>La fonction <strong>Encrypter</strong> permet d'insérer les clés directement dans un fichier d'opération sans risque pour leur confidentialité</p>

</div>
