<div style="display: inline-block">
  <app-hourglass fontsize="xx-small" [message]="message"></app-hourglass>
  <div *ngIf="message?.length==0"
       [ngStyle]="{display:'inline-block','font-size':fontsize,height:'fit-content'}"
       (click)="switch_fiat()">
    {{showValue | number:'1.0-'+decimals }}
    <span *ngIf="showUnity" [ngStyle]="{'font-size':fontsize_unity}">{{unity}}</span>
  </div>

</div>
