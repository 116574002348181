// export let PERMS:any={
//   "799C3Tr6gxdwspPoDEBvFGgLCyzMrSrFjd3CkVW6ukar":["validate","buy","about","help","reload","wallet","keys","mint"],
//   "Ee2zEFPiNhs7ZxuRg7aA1J4qDFNM5yQWH4CtzP7tssAt":["validate","buy","about","help","reload","wallet","keys","mint"],
//   "anonymous":['help','about',"mint","keys","manage","creator"],
//   "connected":['buy','reload','help','about',"wallet","mint","keys","manage"]
// }

import {NetworkService} from "./app/network.service";

export const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEQ4ZTk2MERCRDEwYjVlNTExMTU5REI5RDk3NzQ0MkI2ODI1OWU1MTQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY0NTcyMzU4MDU0MSwibmFtZSI6Im5mbHVlbnQifQ.5rzoF_fn5iZJLY2nD-yTIt3RoxWKoOuh_rPZ-prwnds';

export const NFLUENT_WALLET="https://wallet.nfluent.io";

export const MAX_FILE_SIZE=10000      //En Ko



//Voir https://console.cloud.google.com/apis/credentials/oauthclient/1047581333959-f7rub1f23lkhuts4ig50pabhv3cakf6u.apps.googleusercontent.com?project=nfluenttokenfactory
//export const GOOGLE_CLIENT_ID="167299914377-p8vuf2f6npqnigl5kpqrh34cqjd81eko.apps.googleusercontent.com"
//export const GOOGLE_CLIENT_ID="374328672254-kgeau4348kfsdogmh1glktv3ihj6ui56.apps.googleusercontent.com"
export const GOOGLE_CLIENT_ID="794055474370-nj8dfh004epum0h6ne1chq903dr4rofe.apps.googleusercontent.com"
export const GOOGLE_SECRET="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"
// export const GOOGLE_SECRET_ID="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"

// export const SALT="sel"
// export const TOKEN_FACTORY_WALLET="BjW8USn6XpyCW5dg95bNKey5XZ6KWaTox1nPDFYr4fdc";
// export const PRICE_PER_TOKEN=0.01;

//export const EMAIL_ADMIN="hhoareau@gmail.com"
export const ADDR_ADMIN="erd16ck62egnvmushkfkut3yltux30cvp5aluy69u8p5hezkx89a2enqf8plt8"


