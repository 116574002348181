<div class="mainform" [ngStyle]="ope?.branding?.style" *ngIf="final_message==''">
  <app-hourglass [message]="message"></app-hourglass>

  <div *ngIf="message.length==0 && wallet_link=='' && nft">
    <div class="mat-headline-4 color-primary" *ngIf="title.length>0">{{title}}</div>

    <img [src]="nft?.visual" *ngIf="nft?.visual"
         style="max-width: 200px;width:80%;margin-bottom: 10px;"
         class="mat-elevation-z4">
    <br>

    <div *ngIf="!selfWalletConnexion" style="max-width: 450px;display: inline-block;">

      <app-input style="width:50%;max-width: 300px;" label_button="Envoyer"
                 (valueChange)="address=$event" [value]="address"
                 [label]="prompt" (validate)="validate_input_address()"
                 value_type="text" ></app-input>

      <button type="button" mat-raised-button mat-button
              title="Aide / FAQ"
              *ngIf="help_url.length>5"
              (click)="open_help()">
        Aide
      </button>
      <br>

      <div *ngIf="authentification?.webcam">
        <br>
        Capturez l'adresse d'un wallet<br>
        <app-scanner size="250px" (flash)="onflash($event)"></app-scanner>
      </div>
    </div>


    <!--    gestion de la facture -->
    <app-input *ngIf="ope?.payment?.billing && nft.price>0 && address.length>0 && address.indexOf('@')==-1"
               [value]="billing_address" (valueChange)="billing_address=$event"
               value_type="text"
               label="Indiquez une adresse mail pour recevoir une facture"
               help="Une facture vous seras adressée"></app-input>


    <div *ngIf="address.length>20 || (address.length>5 && address.indexOf('@')>-1)">
      <div *ngIf="nft.price>0">
        <br>
        Acheter maintenant ce NFT pour <strong>{{nft.price}}€</strong><br>


      </div>

    </div>

    <app-authent *ngIf="selfWalletConnexion && authentification"
                 [showGoogle]="authentification.google"
                 [showAddress]="authentification.address"
                 [showWebcam]="authentification.webcam"
                 [showEmail]="authentification.email"
                 [showWalletConnect]="authentification.wallet_connect"
                 (authent)="valide($event)"
                 [prompt]="prompt"
                 (cancel)="cancel()"
                 style="max-height: 300px;max-width: 350px;">
    </app-authent>


  </div>


</div>


<div class="mainform" style="text-align: center;" *ngIf="final_message!=''">
  <div style="font-size: xx-large;width:80%;margin:10%;text-align: center">
    {{final_message}}
  </div>

  <div *ngIf="selfWalletConnexion && wallet_link.length>0">
    Consulter votre wallet pour y retrouver votre nouveau NFT<br>
    <a [href]="wallet_link" target="_blank">Ouvrir votre wallet</a>
  </div>
</div>
